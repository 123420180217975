(function () {
    'use strict';
    $(() => {
        gsap.registerPlugin(ScrollTrigger);
        gsap.core.globals("ScrollTrigger", ScrollTrigger);

        const JRElements = $('.just-reveal');
        const totalJRElements = JRElements.length;

        JRElements.each(function (index) {
            const $this = $(this);
            const target = $this[0];

            let triggerHook = 0.6; // default one for all elements

            if ($('footer').has($this) || $this.height() > $(window).height() * .7) // for footer elements
                triggerHook = .9;

            if (index === totalJRElements - 1) // for last animation element
                triggerHook = 0.98;

            const handleEnter = () => $this.addClass('jr-running');

            ScrollTrigger.create({
                trigger: target,
                id: index + 1,
                start: `top ${triggerHook * 100}%`,
                toggleActions: 'play none none none',
                end: 'top bottom',
                markers: false,
                onEnter: handleEnter,
                onEnterBack: handleEnter
            })
        });
    })
})(jQuery);
