(function () {
    'use strict';
    $(() => {
        if ($('main.recruitment').length) {
            const queryParams = new URLSearchParams(window.location.search);
            const stanowisko = queryParams.get('stanowisko');
            const region = queryParams.get('region');

            const targetNode = document.getElementById('recruitment-wrapper');
            const config = {
                attributes: true,
                childList: true,
                subtree: true
            };
            let updatedForm = false;

            updateRecruitForm();
            const observer = new MutationObserver((mutationsList) => {
                [...mutationsList].forEach((mutation) => {
                    if (mutation.type === 'childList' && !updatedForm) {
                        updateRecruitForm();
                    }
                })
            });
            observer.observe(targetNode, config);


            function updateRecruitForm() {
                const stanowisko_select = document.querySelector('[name="af_31"]');
                const region_select = document.querySelector('#lstAvailableRegions');


                if (stanowisko_select || region_select) {
                    updatedForm = true;
                    if (stanowisko_select) {
                        $(stanowisko_select).find('option').each(function () {
                            if (stanowisko === slug($(this).text())) {
                                $(stanowisko_select).val([$(this).val()]).change();
                                return false;
                            }
                        });
                    }

                    if (region_select) {
                        $(region_select).val([region]).change();
                    }

                    document.querySelector('.skk_filters_btn_search').click();
                }
            }
        }
    })
})(jQuery);
