(function () {
    'use strict';
    $(document).ready(function () {

        if ($('.practices').length) {

            new Swiper('.cta-swiper', {
                slidesPerView: 1,
                loop: true,
                effect: 'fade',
                speed: 1500,
                fadeEffect: {
                    crossFade: true
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
            });

            const testimonialsSwiper = new Swiper('.testimonials-swiper', {
                slidesPerView: 2,
                spaceBetween: 20,
                loop: false,
                pagination: {
                    el: '.swiper-pagination',
                },
            });

            testimonialsSwiper.on('slideChange', function () {
                $('.testimonials-swiper').find('video').each(function() {
                    this.pause();
                    $(this).removeClass('playing');
                });
            });

            $('.testimonial-video').on('click', function() {
                const $clickedVideo = $(this).parent().find('video');
                const clickedVideo = $clickedVideo[0];

                $(this).closest('.testimonials-swiper').find('video').not($clickedVideo).each(function() {
                    this.pause();
                    $(this).removeClass('playing');
                });

                if (clickedVideo.paused) {
                    clickedVideo.play();
                } else {
                    clickedVideo.pause();
                }

                $clickedVideo.toggleClass('playing');
            });

            $('.projects-link').on('click', function(e) {
                e.preventDefault();
                $(this).siblings('.projects-popup').fadeIn({
                    start: function () {
                        $(this).css({
                            display: "flex"
                        })
                    }
                });
            });

            $('.projects-popup-close').on('click', function() {
                $('.projects-popup').fadeOut();
            });
        }
    });
}(jQuery));
