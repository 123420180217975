(function () {
    'use strict';
    $(document).ready(function () {

        if ($('.homepage').length) {
            new Swiper('.hero-swiper', {
                slidesPerView: 1,
                loop: true,
                effect: 'fade',
                speed: 1500,
                fadeEffect: {
                    crossFade: true
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
            });

            new Swiper("#swiper-branches", {
                centeredSlides: true,
                slidesPerView: 'auto',
                loop: true,
                spaceBetween: 50,
                autoplay: {
                    delay: 4000
                },
                breakpoints: {
                    1366: { spaceBetween: 0 },
                    1600: { spaceBetween: 90 },
                },
                navigation: {
                    nextEl: '#swiper-branches .swiper-button-next',
                    prevEl: '#swiper-branches .swiper-button-prev',
                },
            });


            $('.swiper-people').each(function () {
                const config = {
                    slidesPerView: 'auto',
                    spaceBetween: 10,
                    loop: true,
                    autoplay: {
                        delay: 3000
                    },
                    breakpoints: {
                        992: {
                            autoplay: false
                        }
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev',
                    },
                };
                if ($(this).hasClass('people-contact')) {
                    config.effect = 'fade';
                    config.fadeEffect = { crossFade: true };
                }
                new Swiper($(this)[0], config);
            })
        }
    });
}(jQuery));
