(function () {
    'use strict';
    $(document).ready(function () {

        const $header = $('header');
        const $menuToggle = $('.menu-toggle');

        // Smooth scroll to
        $('#main-menu .scroll-animation > a').click(function (e) {

            const href = $(this).attr('href').replace("/", "");
            if (href && $(href).length) {
                console.log("ASD");
                e.preventDefault();

                $("html, body").animate({
                    scrollTop: $(href).offset().top
                }, 1000);

                if (window.innerWidth < 992) {
                    $('.menu-overlay').fadeOut(function () {
                        $(this).css('display', '');
                    });

                    if ($header.hasClass('menu-open'))
                        $header.removeClass('menu-open');
                    if ($menuToggle.hasClass('open'))
                        $menuToggle.removeClass('open');
                }
            }
        });

        //Menu mobile toggle
        $(".menu-toggle").click(function () {
            $('.menu-overlay').fadeToggle();
            $(this).toggleClass("open");
            $('header').toggleClass("menu-open");
        });

        $(window).resize(function () {
            if (window.innerWidth > 991) {
                $('.menu-overlay').css('display', '');
                if ($header.hasClass('menu-open'))
                    $header.removeClass('menu-open');
                if ($menuToggle.hasClass('open'))
                    $menuToggle.removeClass('open');
            } else {

            }
        });

        $(window).scroll(function() {
            if ($(window).scrollTop() > 0) {
                $('header').addClass('fixed');
            } else {
                $('header').removeClass('fixed');
            }
        });

        tippy('[data-tippy-content]', {
            allowHTML: true
        });

    });
}(jQuery));
